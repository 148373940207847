import React, { useState, useCallback, useEffect } from "react";
import queryString from "query-string";

import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  DisplayHomeDesignList,
  InternalLink,
  InternalLinkTypes,
  Layout,
  RangeFilter,
  SEO,
  StoreyFilter,
} from "../components";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col, Space } from "antd";
import { Colors } from "../components";

const HomeDesigns = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allHomeDesignsJson {
        nodes {
          name
          description
          youtubeLink
          slug
          range
          info {
            storey
          }
          albumImagePath {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const homes = data.allHomeDesignsJson.nodes;
  const [filteredHomes, setFilteredHomes] = useState([]);
  const [selectedStorey, setSelectedStorey] = useState("all");

  const onChangeSelectedStorey = useCallback(
    value => {
      setSelectedStorey(value);
    },
    [setSelectedStorey]
  );

  const [selectedRange, setSelectedRange] = useState("all");

  const onChangeSelectedRange = useCallback(
    value => {
      setSelectedRange(value);
    },
    [setSelectedRange]
  );

  useEffect(() => {
    if (location && location.search) {
      const params = queryString.parse(location.search);
      if (params.storey) {
        setSelectedStorey(params.storey);
      }
      if (params.range) {
        setSelectedRange(params.range);
      }
    }
  }, [location, setSelectedStorey, setSelectedRange]);

  useEffect(() => {
    let filtered = [];
    homes.forEach(home => {
      if (
        (selectedStorey.toString() === home.info.storey.toString() ||
          selectedStorey.toString() === "all") &&
        (selectedRange.toString() === home.range.toString() ||
          selectedRange.toString() === "all")
      )
        filtered.push(home);
    });

    setFilteredHomes(filtered);
  }, [homes, selectedStorey, selectedRange]);

  return (
    <Layout>
      <SEO
        title="Home Designs"
        description={"Browse through our Custom Home Designs"}
      />
      <BannerPage
        title={"Home Designs"}
        subTitle={"Browse through our Custom Home Designs"}
      />
      <ContainerContent
        style={{ backgroundColor: Colors.LightGray, paddingTop: 0 }}
      >
        <ContainerContentFixed>
          <Space direction="vertical" size={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <RangeFilter
                  selectedFilter={selectedRange}
                  onChangeFilter={onChangeSelectedRange}
                />
              </Col>
              <Col>
                <StoreyFilter
                  selectedFilter={selectedStorey}
                  onChangeFilter={onChangeSelectedStorey}
                />
              </Col>
            </Row>
            <DisplayHomeDesignList homeDesigns={filteredHomes} />
            <section style={{ textAlign: "center" }}>
              <InternalLink url={InternalLinkTypes.Disclaimer}>
                See our disclaimer
              </InternalLink>
            </section>
          </Space>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        title={"Can't find the home design you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        primaryText={"Customise your own"}
        primaryLink={InternalLinkTypes.GetStarted}
        secondaryLink={InternalLinkTypes.FloorPlans}
        secondaryText={"See Floor Plans"}
      />
    </Layout>
  );
};

export default HomeDesigns;
